import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';

import { DynamicHtmlDirective, NativeAppService, UserService } from '@frontend/vanilla/core';

import { ClientRTMSResourceService } from './client-rtms-resource.service';

interface MessageToNativeInterface {
    getRtmsData(eventNm: any): void;
    closeContainer(param: any): void;
    NativeDispatch(eventName: any, param: any): void;
}
declare let MessageToNative: MessageToNativeInterface;

export interface CtaDetails {
    link: string;
    nativeEvent: string;
}

@Component({
    selector: 'pt-client-rtms',
    templateUrl: 'client-rtms.component.html',
    imports: [CommonModule, DynamicHtmlDirective],
    standalone: true,
})
export class ClientRTMSComponent implements OnInit {
    initData: any;
    content: any;
    promoData: any;
    notificationType: any;
    basetemplateId1: any;
    basetemplateId2: any;
    overlayTitle: any;
    overlayImage: any;
    overLayDescription: any;
    overlayCTA: any;
    overlayManualTermsAndConditions: any;
    tosterImage: any;
    toasterDescription: any;
    toasterCTA: any;
    isOverlay: boolean;
    index1: number;
    index2: number;
    overlayCtaDetails: CtaDetails = {
        link: '',
        nativeEvent: '',
    };
    toasterCtaDetails: CtaDetails = {
        link: '',
        nativeEvent: '',
    };

    constructor(
        private nativeApp: NativeAppService,
        private userService: UserService,
        public rtmsService: ClientRTMSResourceService,
    ) {}

    ngOnInit(): void {
        const userAgent: any = navigator.userAgent;
        if (userAgent.match(/Android/)) {
            MessageToNative.getRtmsData('GET_RTMS_DATA');
        } else this.requestRTMSContent();

        this.nativeApp.eventsFromNative.subscribe((e) => {
            if (e.eventName === 'SET_RTMS_DATA') {
                this.notificationType = e.parameters?.notificationType;
                this.basetemplateId1 = e.parameters?.baseTempletId;
                this.promoData = e.parameters;
                this.basetemplateId2 = this.basetemplateId1.replace(/(\/id\/)/g, '');
                if (this.notificationType.toLowerCase() === 'overlay') this.isOverlay = true;
                else this.isOverlay = false;
                this.getSitecoreData();
            }
        });
    }

    getSitecoreData() {
        const userAgent: any = navigator.userAgent;
        this.rtmsService.getInitValues(this.notificationType, this.basetemplateId2).subscribe(
            (response: any) => {
                this.initData = response.rtmsData;

                const properties = [
                    { key: 'overlayTitle', fallback: 'commonTitle' },
                    { key: 'overLayDescription', fallback: 'commonDescription' },
                    { key: 'overlayCTA', fallback: 'commonCTA' },
                    { key: 'overlayManualTermsAndConditions', fallback: 'commonTermsAndConditions' },
                    { key: 'toasterDescription', fallback: 'postAcceptanceDescription' },
                    { key: 'toasterCTA', fallback: 'postAcceptanceCTA' },
                ];
                properties.forEach((prop) => {
                    this[prop.key] =
                        this.initData && this.initData[prop.key] ? this.initData[prop.key] : this.initData && this.initData[prop.fallback];
                });
                const imageProperties = [
                    { key: 'overlayImage', primary: 'overlayImage', fallback: 'detailImage' },
                    { key: 'tosterImage', primary: 'tosterImage', fallback: 'preAcceptanceImage' },
                ];
                imageProperties.forEach((prop) => {
                    this[prop.key] =
                        this.initData && this.initData[prop.primary] && this.initData[prop.primary].src
                            ? this.initData[prop.primary].src
                            : this.initData && this.initData[prop.fallback] && this.initData[prop.fallback].src;
                });

                const ctaDetails = [
                    {
                        key: 'overlayCTA',
                        target: 'overlayCtaDetails',
                        searchTerm1: 'href=',
                        searchTerm2: ' class=',
                        linkKey: 'link',
                        addIndex1: 6,
                    },
                    {
                        key: 'overlayCTA',
                        target: 'overlayCtaDetails',
                        searchTerm1: 'data-send-native=',
                        searchTerm2: '><strong>',
                        linkKey: 'nativeEvent',
                        addIndex1: 18,
                    },
                    {
                        key: 'toasterCTA',
                        target: 'toasterCtaDetails',
                        searchTerm1: 'href=',
                        searchTerm2: ' data-send-native',
                        linkKey: 'link',
                        addIndex1: 6,
                    },
                    {
                        key: 'toasterCTA',
                        target: 'toasterCtaDetails',
                        searchTerm1: 'data-send-native=',
                        searchTerm2: '>',
                        linkKey: 'nativeEvent',
                        addIndex1: 18,
                    },
                ];
                ctaDetails.forEach((cta) => {
                    const ctaContent = this[cta.key];
                    const index1 = ctaContent ? ctaContent.search(cta.searchTerm1) : -1;
                    if (index1 >= 0) {
                        const index2 = ctaContent.search(cta.searchTerm2);
                        this[cta.target][cta.linkKey] = ctaContent.substring(index1 + cta.addIndex1, index2 - 1);
                    }
                });
            },
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            (error) => {
                if (userAgent.match(/Android/)) {
                    MessageToNative.closeContainer({ eventName: 'RTMS_ERROR', parameters: { params: this.promoData } });
                } else this.nativeApp.sendToNative({ eventName: 'RTMS_ERROR', parameters: { params: this.promoData } });
            },
        );
    }

    linkToAction(promoCta: any) {
        const clientEvent = promoCta.nativeEvent;
        const userAgent: any = navigator.userAgent;
        if (clientEvent) {
            if (userAgent.match(/Android/)) {
                MessageToNative.NativeDispatch(clientEvent, null);
                MessageToNative.closeContainer(clientEvent);
            } else {
                this.nativeApp.sendToNative({ eventName: clientEvent, parameters: {} });
                this.nativeApp.sendToNative({ eventName: 'closeContainer', parameters: {} });
            }
        } else {
            const sso = this.userService.ssoToken;
            window.open(promoCta.link + `?sessionKey=${sso}`);
            if (userAgent.match(/Android/)) {
                MessageToNative.closeContainer(clientEvent);
            } else {
                this.nativeApp.sendToNative({ eventName: 'closeContainer', parameters: {} });
            }
        }
    }

    closeContainer() {
        if (this.nativeApp.isDownloadClient) this.nativeApp.sendToNative({ eventName: 'closeContainer', parameters: {} });
    }

    requestRTMSContent() {
        this.nativeApp.sendToNative({ eventName: 'GET_RTMS_DATA' });
    }
}
